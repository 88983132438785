<template>
	<w-layout row :justify-space-between="!icon" :justify-center="icon" align-center>
		<w-flex v-if="!icon" xs9>
			<w-text-input
				v-model="application.google_play_store_url"
				disabled
				auto-focus
				color="primary"
				:label="$t('mobileapp.fields.google_play_store_url')"
			></w-text-input>
		</w-flex>
		<w-flex v-if="application.google_play_store_url" :offset-xs1="!icon" :xs2="!icon" :shrink="icon">
			<a :href="application.google_play_store_url" target="_blank">
				<v-img src="/img/google-play-store-download.png" contain max-height="48px" min-width="100px" max-width="100px" />
			</a>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppPlayStoreLink',
	props: {
		value: {
			required: true,
			type: Object
		},
		icon: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
